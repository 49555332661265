<template>
  <SummaryBudgetView
    :columns-to-summary="columnsToSummary"
    :over-condition-obj="overConditionObj"
    :chart-data="getPowerSumValues" />
</template>
<script>
import {
  mapState, mapGetters,
} from 'vuex';
import SummaryBudgetView from '@/components/ProjectDetails/ProjectDetailsSummary/ProjectDetailsSummaryContainer';
export default {
  name: 'ProjectDetailsSummarySustainabilityView',
  components: {
    SummaryBudgetView,
  },
  computed: {
    ...mapState('ProjectAggregation', ['aggregationData']),
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapGetters({
      areaUnitTitle: 'ProjectDetails/areaUnitTitle',
      getPowerSumValues: 'ProjectAggregation/getPowerSumValues',
      getTotalPower: 'ProjectAggregation/getTotalPower',
    }),
    overConditionObj() {
      return {
        title: `${this.powerConditionText} target`,
        value: this.powerConditionValue,
        criteria: this.powerConditionText,
        unitTitle: 'Watts',
      };
    },
    columnsToSummary() {
      return [
        {
          colTitle: 'Target Power',
          value: `${this.targetPowerAmout} Watts`,
          colValues: [
            {
              title: 'Amount (Watts)', value: this.targetPowerAmout,
            },
            {
              title: `Area (${this.areaUnitTitle})`, value: this.targetArea,
            },
            {
              title: `Power Density (W/${this.areaUnitTitle})`, value: this.targetPowerDensity,
            },
          ],
        },
        {
          colTitle: 'Current Power',
          value: `${+this.getTotalPower.toFixed(2)} Watts`,
          colValues: [
            {
              title: 'Amount (Watts)', value: +this.getTotalPower.toFixed(2),
            },
            {
              title: `Area (${this.areaUnitTitle})`, value: this.targetArea,
            },
            {
              title: `Power Density (W/${this.areaUnitTitle})`, value: this.currentPowerDensity,
            },
          ],
        },
      ];
    },
    powerConditionText() {
      if (!(this.aggregationData && this.detailProjectData)) return '';
      return this.getTotalPower < this.targetPowerAmout
        ? 'Under'
        : 'Over';
    },
    powerConditionValue() {
      if (!(this.aggregationData && this.detailProjectData)) return 0;
      const value = this.targetPowerAmout - this.getTotalPower;
      return +value.toFixed(2);
    },
    targetArea() {
      return this.detailProjectData?.area || 0;
    },
    targetPowerAmout() {
      return +(this.targetPowerDensity * this.targetArea).toFixed(2);
    },
    targetPowerDensity() {
      return this.detailProjectData?.powerDensity || 0;
    },
    currentPowerDensity() {
      const value = this.getTotalPower / this.targetArea;
      if (!isFinite(value)) {
        return 0;
      } return +value.toFixed(2);
    },
  },
};
</script>
