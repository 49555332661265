import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions('ScheduleViews', ['setGalleryToAllScheduleViews']),
    setGalleryViewOnMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.setGalleryToAllScheduleViews();
      }
    },
  },
  watch: {
    '$vuetify.breakpoint.smAndDown': {
      handler() {
        this.setGalleryViewOnMobile();
      },
    },
  },
};
