<template>
  <div v-if="alertStatus">
    <div
      class="border-item"
      :style="`background-color: var(--v-${alertOptions.color}-base)`" />
    <v-tooltip
      v-if="alertOptions.showTooltip"
      top>
      <template #activator="{ on }">
        <v-icon
          class="show-warning-block"
          size="22"
          :color="alertOptions.color"
          @click="alertClicked"
          v-on="on">
          mdi-alert
        </v-icon>
      </template>
      <span>{{ alertOptions.title }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import ComparisonApi from '@/services/graphql/comparison';
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import {
  ADDED, DELETED, MODIFIED,
} from '@/constants';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
export default {
  name: 'TableActionsCompareModified',
  props: {
    alertStatus: {
      type: String,
      validator(value) {
        return [ADDED, DELETED, MODIFIED].includes(value);
      },
      default: '',
    },
    rowId: {
      type: String,
      default: null,
    },
    tableId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentVersion: 'ProjectVersions/getCurrentVersion',
    }),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('ScheduleCompare', ['compareVersion']),

    alertOptions() {
      const { alertStatus: status } = this;
      if (!status) return {
      };
      const ALERTS = [
        {
          title: 'Added',
          color: 'green',
          value: ADDED,
          showTooltip: true,
        },
        {
          title: 'Deleted',
          color: 'red',
          value: DELETED,
          showTooltip: true,
        },
        {
          title: 'Modified',
          color: 'yellow',
          value: MODIFIED,
          showTooltip: true,
        },
      ];
      return ALERTS.find(({ value } ) => value === status);
    },
  },
  methods: {
    ...mapActions(['handleError']),
    ...mapMutations({
      spinner: 'spinner',
      setModifiedRow: 'ScheduleCompare/setModifiedRow',
    }),
    async alertClicked() {
      if (this.alertStatus !== MODIFIED) return;

      try {
        const { tableId, rowId, currentVersion, compareVersion, activeWorkspaceId: workspaceId } = this;
        const jsonForRequest = {
          rowVersionId: currentVersion === DEFAULT_PROJECT_VERSION ? '' : currentVersion,
          compareVersion: compareVersion === DEFAULT_PROJECT_VERSION ? '' : compareVersion,
        };
        this.spinner(true);
        const { data } = await ComparisonApi.getRowDiffProduct({
          tableId,
          rowId,
          tableType: 'schedule',
          ...jsonForRequest,
          workspaceId,
        });
        const { fields } = data.response;
        this.setModifiedRow({
          fields,
          rowId,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.border-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
}
.show-warning-block {
  position: absolute;
  top: 0px;
  right: 0;
  cursor: pointer;
  &__bottom {
    bottom: 0;
    top: inherit;
  }
}
</style>
