import { mapMutations } from 'vuex';
import { debounce } from 'lodash';
const DEBOUNCE_TIME = 900; // in milliseconds;
const MIN_COL_WIDTH = 90;
import {
  HEIGHT_OF_ROWS, ROW_ADDITIONAL_STEP,
} from '@/constants/heightMappingTableValues';
import { waitTimeout } from '@/utils';
export default {
  props: {
    isListingView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    colsToShow: 0,
    start: 0,
    oldStartValue: 0,
    tableHeight: 0,
  }),
  methods: {
    ...mapMutations(['spinner']),
    onScroll(e) {
      this.debouncedScroll(e);
    },
    scrollForEnhancements(e) {
      const { scrollTop, scrollLeft } = e.target;
      const rows = Math.ceil(scrollTop / this.rowHeight);
      this.start = scrollTop < 0 ? 0 : rows;
      const cols = Math.ceil(scrollLeft / MIN_COL_WIDTH);
      this.colsToShow = cols;
    },
    debouncedScroll: debounce(function(e) {
      this.scrollForEnhancements(e);
    }, DEBOUNCE_TIME),
    getIndexOfRow(id) {
      return this.mappedDataIds.indexOf(id);
    },
    async onTableMounted(customId = '') {
      this.start = 0;
      this.oldStartValue = 0;
      let tableWrapper = null;
      // NOTE: Why are there corrections here?
      // The point is that we now have two table components on the same page.
      // And when we try to find an element by class, we get two elements.
      // Referencing by idi, fixes this problem.
      if (customId) {
        const wrapper = document.getElementById(customId);
        tableWrapper = wrapper && wrapper.getElementsByClassName('v-data-table__wrapper')[0];
      }
      if (tableWrapper) {
        tableWrapper.addEventListener('scroll', this.onScroll);
        await waitTimeout(400);
      }
    },
    setCellShowing({ cellIndex: index, indexOfRow, showDefaultCells = false } = {
    }) {
      const QUANTITY_CELLS_TO_SHOW = 3;
      const {
        approxCapacityOfRowsOnScreen: rowsCapacity,
        approxCapacityOfColsOnScreen: colsCapacity,
      } = this;
      if (showDefaultCells || index < QUANTITY_CELLS_TO_SHOW) {
        return true;
      }
      if (this.isListingView) {
        return false;
      }
      const checkForIntermediateRows = indexOfRow + rowsCapacity > this.start
        && indexOfRow - rowsCapacity < this.start;
      const checkCellsToShow = this.colsToShow + colsCapacity > index;
      return checkForIntermediateRows && checkCellsToShow;
    },
    showColumns({ rowId: id, colIndex: index }) {
      const QUANTITY_CELLS_TO_SHOW = 3;
      const {
        approxCapacityOfRowsOnScreen: rowsCapacity,
        approxCapacityOfColsOnScreen: colsCapacity,
      } = this;
      if (index < QUANTITY_CELLS_TO_SHOW) {
        return true;
      }
      if (this.isListingView) {
        return false;
      }
      const { getIndexOfRow } = this;
      const indexOfRow = getIndexOfRow(id);
      const checkForIntermediateRows = indexOfRow + rowsCapacity > this.start
        && indexOfRow - rowsCapacity < this.start;
      const checkCellsToShow = this.colsToShow + colsCapacity > index;
      return checkForIntermediateRows && checkCellsToShow;
    },
  },
  computed: {
    rowHeight() {
      return HEIGHT_OF_ROWS[this.selectedViewCriteria] + ROW_ADDITIONAL_STEP;
    },
    initialArrLength() {
      return this.data.length;
    },
    mappedDataIds() {
      return this.data.map(item => item.id);
    },
    clientScreenHeight() {
      const height = window?.innerHeight || 948;
      return height;
    },
    clientScreenWidth() {
      const width = window?.innerWidth || 1920;
      return width;
    },
    approxCapacityOfRowsOnScreen() {
      const rows = Math.ceil(this.clientScreenHeight / this.rowHeight) * 1.2;
      return rows;
    },
    approxCapacityOfColsOnScreen() {
      const rows = Math.ceil(this.clientScreenWidth / MIN_COL_WIDTH) * 3;
      return rows;
    },
  },
};
