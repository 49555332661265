<template>
  <app-dialog
    v-model="dialogForControls"
    :value.sync="dialogForControls"
    :disabled="!canViewInfo.allowed"
    width="649"
    content-class="v-dialog__form v-dialog__max-h">
    <v-card class="h-100">
      <CardTitle
        :is-edit-mode="isEditMode"
        :name="item.name"
        :new-name="newName"
        :view-name.sync="viewName"
        @cancel="onCancelClick"
        @edit="onEditViewNameClick"
        @save="onSaveNameBtnClick" />
      <v-card-text>
        <v-tabs
          v-model="chooseControl"
          class="flex-grow-0"
          :mobile-breakpoint="undefined"
          :show-arrows="false">
          <v-tab
            class="tabsStyles">
            Share
          </v-tab>
          <v-tab
            class="tabsStyles">
            Fields control
          </v-tab>
          <v-tab
            v-if="createNewProjectView"
            class="tabsStyles">
            Sorting
          </v-tab>
          <v-tab
            v-if="createNewProjectView"
            class="tabsStyles">
            Filtering
          </v-tab>
          <v-spacer />
          <SwitcherToListing
            :value="isListingView"
            @toggleViewMode="toggleViewMode" />
        </v-tabs>
        <div
          style="min-height: 230px;"
          class="mt-5 flex-grow-1">
          <div v-if="chooseControl === 0">
            <AppShareAutocomplete
              :users-initial="usersInitial"
              higlight-users
              placeholder="Share with people"
              @remove="usersToRemove"
              @selected="selectedUsers" />
            <div
              class="items-selection mt-5 ml-3">
              <v-checkbox
                v-for="el in view"
                :key="el.id"
                v-model="selectedView"
                :label="toUpperViews(el.role)"
                :value="el" />
            </div>
          </div>
          <div
            v-else-if="chooseControl === 1"
            class="items-selection m-0">
            <ProjectDetailControlFields
              ref="fieldsControl"
              :item="item"
              :default-fields="actualCustomDefaultFields"
              :selected-fields="item.columnOrder"
              :status-locked-all-fields.sync="statusLockedAllFields"
              :fields-to-hide-toggle="productHeaders.DISABLED_IN_SETTINGS"
              draggable-fields
              @update="onUpdateFields"
              @closeWindow="closeWindow">
              <template #topAction>
                <div class="d-flex">
                  <RestoreDefaultView
                    @restore="onRestore" />
                  <project-details-views-lock-editing
                    :status-locked-all-fields="statusLockedAllFields"
                    class="ml-5"
                    @lockUnlockAllFields="lockUnlockAllFields" />
                </div>
              </template>
            </ProjectDetailControlFields>
          </div>
          <div
            v-else-if="chooseControl === 2"
            style="height: 100%; min-height: 230px;"
            class="d-flex flex-column m-0">
            <v-text-field
              v-model="searchFieldSorting"
              clearable
              placeholder="Search fields..."
              autocomplete="off" />
            <v-radio-group
              v-model="selectedFieldSorting"
              class="items-selection overflow-auto">
              <v-radio
                v-for="el in fieldSortingList"
                :key="el"
                class="mb-6 ml-3"
                :label="el"
                :value="el" />
            </v-radio-group>
          </div>
          <div
            v-else
            style="height: 100%; min-height: 230px;"
            class="d-flex flex-column m-0">
            <v-text-field
              v-model="searchTagsFiltering"
              clearable
              placeholder="Search tags..."
              autocomplete="off" />
            <div class="items-selection overflow-auto">
              <div
                v-for="(field) in tagsFilteringList"
                :key="field"
                class="align-center mb-6 ml-3">
                <v-switch
                  class="d-flex align-center switcher"
                  color="blue"
                  :label="field"
                  hide-details
                  :input-value="selectedTagsFiltering.includes(field)"
                  @change="updateTagsFiltering(field)" />
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <ActionButtons
        v-if="chooseControl !== 1"
        submit-text="Save"
        cancel-text="Cancel"
        @onCancelClick="onCancelClick"
        @onSubmitClick="control" />
    </v-card>
  </app-dialog>
</template>
<script>
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';
import {
  cloneDeep, isEqual,
} from 'lodash';

import productHeaders from '@/constants/productHeaders';
import { SORTABLE_FIELDS_PROJECTS } from '@/constants/scheduleViews';
import { TYPE_EDITOR } from '@/constants/userPermissions';

import AppShareAutocomplete from '@/components/App/AppShareAutocomplete';
import ProjectDetailControlFields from '@/components/ProjectDetails/ProjectDetailControlFields';
import ProjectDetailsViewsLockEditing from '@/components/ProjectDetails/ProjectDetailsViews/ProjectDetailsViewsLockEditing';
import SwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';

import ProjectViewsApi from '@/services/graphql/projectViews';
import ScheduleCells from '@/services/graphql/scheduleCells';

import { referenceSortBy } from '@/utils';

export default {
  name: 'ProjectDetailControlsView',
  components: {
    AppShareAutocomplete,
    ProjectDetailControlFields,
    ProjectDetailsViewsLockEditing,
    SwitcherToListing,
    ActionButtons: () => import('../ProjectDetailControlsViewActions'),
    CardTitle: () => import('../ProjectDetailsViewsCardTitle'),
    RestoreDefaultView: () => import('../ProjectDetailsViewsRestoreDefault'),
  },
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
    setSelectedView: {
      type: Array,
      default: () => [],
    },
    queryInformation: {
      type: Object,
      default: () => {
      },
    },
    usersInitial: {
      type: Array,
      default: () => [],
    },
    usersToDelete: {
      type: Array,
      default: () => [],
    },
    rolesToDelete: {
      type: Array,
      default: () => [],
    },
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      statusLockedAllFields: false,
      oldCustomColumnOrder: [],
      actualCustomColumnOrder: [],
      oldCustomDefaultFields: [],
      actualCustomDefaultFields: [],
      users: [],
      selectedView: [],
      chooseControl: 0,
      viewName: '',
      newName: '',
      isEditMode: false,
      productHeaders,
      view: [
        {
          role: 'viewer',
        },
        {
          role: 'collaborator',
        },
        {
          role: 'editor',
        },
      ],
      searchFieldSorting: '',
      selectedFieldSorting: '',
      fieldSorting: SORTABLE_FIELDS_PROJECTS,
      searchTagsFiltering: '',
      selectedTagsFiltering: [],
      tagsFiltering: ['Editable'],
    };
  },
  computed: {
    ...mapState(['role']),
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId', 'defaultFields']),
    ...mapState('ScheduleViews', ['views', 'selectedViewId']),
    ...mapState({
      currentSelectedView: state => state.ScheduleViews.selectedView,
    }),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('UserRoles', ['canModifyViews']),
    ...mapGetters('FeatureFlags', ['createNewProjectView']),
    dialogForControls: {
      get() {
        return this.dialogStatus;
      },
      set(val) {
        this.$emit('update:dialogStatus', val);
      },
    },
    projectId() {
      return this.$route.params.id;
    },
    renderedCustomDefaultFields() {
      const customDefaultFields = this.item.customDefaultFields;
      return customDefaultFields?.length ? customDefaultFields : this.defaultFields;
    },
    renderedCustomColumnOrder() {
      const customColumnOrder = this.item.customColumnOrder;
      return customColumnOrder?.length ? customColumnOrder : this.item.columnOrder;
    },
    isListingView() {
      return this.item?.viewMode === 'gallery';
    },
    canViewInfo() {
      return this.canModifyViews(this.role);
    },
    fieldSortingList() {
      const { searchFieldSorting, fieldSorting } = this;
      if (searchFieldSorting) {
        return fieldSorting.filter(el => el.toLowerCase().includes(searchFieldSorting.toLowerCase()));
      }
      return fieldSorting;
    },
    tagsFilteringList() {
      const { searchTagsFiltering, tagsFiltering } = this;
      if (searchTagsFiltering) {
        return tagsFiltering.filter(el => el.toLowerCase().includes(searchTagsFiltering.toLowerCase()));
      }
      return tagsFiltering;
    },
  },
  watch: {
    setSelectedView(views) {
      this.selectedView = views;
    },
    dialogForControls(val) {
      if (!val) {
        this.viewName = '';
        this.selectedView = '';
        this.isEditMode = false;
        this.newName = '';
      } else {
        this.rewriteData();
        this.$emit('closeAutocomplete');
      }
    },
    currentViewInfo: {
      get() {
        return this.sortedViewInfo;
      },
      set(val) {
        this.setViewInfo(val);
      },
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapMutations('ScheduleViews', ['setViews']),
    ...mapMutations('ProjectDetailsTableSchedule', ['setDefaultFields']),
    ...mapActions('ScheduleViews', ['updateViewSettings', 'updateViewColumnOrder', 'resetView']),
    ...mapActions('ProjectDetailsTableSchedule', ['getSchedule']),
    ...mapActions(['handleError']),
    ...mapActions('ScheduleRows', ['getRows', 'setViewInfo', 'sortedViewInfo']),
    async rewriteData() {
      this.spinner(true);
      try {
        const clonedColumnOrder = cloneDeep(this.renderedCustomColumnOrder);
        this.oldCustomColumnOrder = clonedColumnOrder;
        this.actualCustomColumnOrder = clonedColumnOrder;
        const clonedDefaultFields = cloneDeep(this.renderedCustomDefaultFields);
        this.oldCustomDefaultFields = clonedDefaultFields;
        this.actualCustomDefaultFields = clonedDefaultFields;
        // Get column data for sorting and filtering and initialize selected data
        this.selectedFieldSorting = '';
        this.fieldSorting.forEach((val) => {
          if (val === this.item.sortingField) {
            this.selectedFieldSorting = val;
          }
        });
        // Get tag data for sorting and filtering
        this.selectedTagsFiltering = [];
        const { data: { response: { customValues = [] } } } = await ScheduleCells.getDropdownValues({
          col: 'Tag',
          projectId: this.$route.params.id,
          scheduleId: this.scheduleId,
        });
        this.tagsFiltering = customValues.map((tag) => {
          if (this.item.filterTag?.includes(tag) && this.selectedTagsFiltering.indexOf(tag) === -1) {
            this.selectedTagsFiltering.push(tag);
          }
          return tag;
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.spinner(false);
      }
    },
    lockUnlockAllFields() {
      //invoking function from child component that locking all fields
      this.$refs.fieldsControl.lockUnlockAllFields();
    },
    async onRestore() {
      try {
        await this.resetView({
          viewId: this.queryInformation.viewId,
          projectId: this.queryInformation.projectId,
          scheduleId: this.scheduleId,
          workspaceId: this.activeWorkspaceId,
        });
        if (this.queryInformation.viewId == this.selectedViewId) {
          this.$emit('update-headers');
          this.rewriteData();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async lockFields(columnsToRestrict) {
      if (!columnsToRestrict) return false;
      try {
        this.spinner(true);
        const { activeWorkspaceId: workspaceId, scheduleId: resourceId, projectId } = this;
        const resourceType = 'schedule';
        const type = TYPE_EDITOR;
        const initData = await ProjectViewsApi.restrictColumnEditByRole({
          workspaceId,
          resourceType,
          resourceId,
          projectId,
          type,
          columnsToRestrict,
        });
        await this.getSchedule({
          sharedLink: false,
          initData,
          projectId,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async onUpdateFields(visibleColumns, orderFields, customDefaultFields, columnsToRestrict) {
      let customColumnOrder = [];
      try {
        await this.updateViewSettings({
          viewId: this.item.id,
          visibleColumns,
        });
        const { projectId, activeWorkspaceId: workspaceId } = this;
        customColumnOrder = referenceSortBy(customDefaultFields, visibleColumns);
        if (!isEqual(orderFields, this.oldCustomColumnOrder) || !isEqual(customDefaultFields, this.oldCustomDefaultFields)) {
          await this.updateViewColumnOrder({
            variables: {
              workspaceId,
              resourceType: 'schedule',
              resourceId: this.scheduleId,
              projectId,
              viewId: this.item.id,
              customColumnOrder,
              customDefaultFields,
            },
          });
        }
        await this.lockFields(columnsToRestrict);
        if (this.item.id === this.selectedViewId) {
          this.$emit('update-headers');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async onSaveNameBtnClick() {
      try {
        this.spinner(true);
        let { data } = await ProjectViewsApi.updateCustomViewName({
          scheduleId: this.scheduleId,
          viewId: this.queryInformation.viewId,
          projectId: this.queryInformation.projectId,
          name: this.viewName,
          workspaceId: this.activeWorkspaceId,
        });
        this.newName = data.response.name;
        this.isEditMode = false;
        const newViews = this.views.map(view => (
          view.id === this.queryInformation.viewId ? {
            ...view, name: this.newName,
          } : view
        ));
        this.setViews(newViews);
        this.spinner(false);
      } catch (err) {
        this.spinner(false);
        this.handleError(err);
      }
    },
    onCancelClick() {
      this.dialogForControls = false;
      this.actualCustomColumnOrder = this.oldCustomColumnOrder;
      this.oldCustomDefaultFields = this.oldCustomDefaultFields;
    },
    async toggleViewMode(val) {
      await this.updateViewSettings({
        viewId: this.item.id,
        viewMode: val ? 'gallery' : 'listing',
      });
    },
    onEditViewNameClick() {
      this.viewName = this.newName ? this.newName : this.item.name;
      this.isEditMode = true;
    },
    async control() {
      if (this.selectedView.length) {
        const roles = this.selectedView.map(el => el.role);
        this.$emit('initRolesToDelete', roles);
        await this.grantPermissionForRoles({
          ...this.queryInformation,
          roles,
        });
      }
      if (this.users.length) {
        const usernames = this.users.map(el => el.id);
        await this.grantPermissionForUsers({
          ...this.queryInformation,
          usernames,
        });
      }
      await this.onUpdateViewSettings({
        sortingField: this.selectedFieldSorting,
        filterTag: this.selectedTagsFiltering.length ? JSON.stringify(this.selectedTagsFiltering) : '',
      });
      this.conditionForDelete();
      this.dialogForControls = false;
    },
    conditionForDelete() {
      if (this.usersToDelete.length) {
        this.deletePermissionForUsers({
          ...this.queryInformation,
          usernames: this.usersToDelete,
        });
      }
      if (this.rolesToDelete.length) {
        this.deletePermissionForRoles({
          ...this.queryInformation,
          roles: this.rolesToDelete,
        });
      }
    },
    async deletePermissionForRoles(variables) {
      this.spinner(true);
      try {
        await ProjectViewsApi.deletePermissionForRoles(variables);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async deletePermissionForUsers(variables) {
      this.spinner(true);
      try {
        await ProjectViewsApi.deletePermissionForUsers(variables);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async grantPermissionForUsers(variables) {
      this.spinner(true);
      try {
        await ProjectViewsApi.grantPermissionForUsers(variables);
      } catch (err) {
        this.handleError(err);
      }
      this.spinner(false);
    },
    async grantPermissionForRoles(variables) {
      this.spinner(true);
      try {
        await ProjectViewsApi.grantPermissionForRoles(variables);
      } catch (err) {
        this.handleError(err);
      }
      this.spinner(false);
    },
    selectedUsers(selected) {
      this.users = selected;
    },
    usersToRemove(item) {
      this.$emit('usersToRemove', item);
    },
    toUpperViews(item) {
      return item[0].toUpperCase() + item.substring(1);
    },
    closeWindow() {
      this.onCancelClick();
      this.dialogForControls = false;
      this.isEditMode = false;
      this.chooseControl = 0;
    },
    async onUpdateViewSettings({ filterTag, sortingField }) {
      await this.updateViewSettings({
        viewId: this.item.id,
        sortingField: sortingField,
        filterTag: filterTag,
      });
    },
    updateTagsFiltering(tag) {
      if (!this.selectedTagsFiltering.includes(tag)) {
        this.selectedTagsFiltering.push(tag);
      } else {
        this.selectedTagsFiltering = this.selectedTagsFiltering.filter(el => el !== tag);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-icon {
  transition: 0.7s;
  cursor: pointer;
  opacity: 0.8;
  &.--no-transition {
    transition: 0s;
    &:hover {
      transform: unset !important;
    }
  }
}
.tabsStyles {
  font-size: 12px;
}
.v-card__text {
  padding: 5px 25px 25px 25px !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
}
.v-input--selection-controls {
  margin: 0 !important;
}
.v-slide-group__prev {
  display: none !important;
}

.items-selection {
  justify-content: space-between;
  height: 100%;
  overflow: auto;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep .listing-container {
    .v-input__slot {
      flex-direction: column;
    }
  }
}
</style>
