var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-1 d-flex justify-end align-center",staticStyle:{"width":"30px","position":"absolute","right":"0","top":"12px","z-index":"350"},on:{"mouseover":_vm.onAction,"mouseleave":function($event){return _vm.$emit('mouse-action-end')}}},[_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"10","content-class":"edit-menu","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pointer"},on),[_vm._v(" mdi-menu-down ")])]}}])},[_c('v-card',{staticClass:"nav-card nav-card__actions py-2"},[(_vm.isCurrentColumnSorting)?_c('div',{staticClass:"px-3",on:{"click":function($event){return _vm.$emit('sort', {
          sortOrder: _vm.SORT_TOP,
          sortBy: _vm.defaultSortBy,
        })}}},[_vm._v(" Remove Sort ")]):_c('div',{staticClass:"px-3",on:{"click":function($event){return _vm.$emit('sort', {
          sortOrder: _vm.SORT_TOP,
          sortBy: _vm.sortBy,
        })}}},[_vm._v(" Sort by ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }