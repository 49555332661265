import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions({
      getAvatar: 'getAvatar',
    }),
    getAvatarsForComments(comment) {
      const { creator } = comment ?? {
      };
      const { picture: key } = creator ?? {
      };
      if (!key) return;
      if (!this.avatars[key]) {
        this.getAvatar({
          key,
          getAvatars: true,
          context: this,
        });
      }
    },
  },
};
