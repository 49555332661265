var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.header.value === 'order' && 'table-cell-wrapper__order',
    _vm.header.value === 'order' && _vm.getActiveRowIds.includes(_vm.item.id) && 'table-cell-wrapper__order--active',
    ("order-cell--" + _vm.selectedViewCriteria)
  ],on:{"mouseleave":_vm.onRemoveHeader,"mouseover":function($event){return _vm.onSetHeader(_vm.header.value)}}},[(_vm.header.value === 'order')?[(_vm.isResultCompare && _vm.compareData.status)?_c('TableActionsCompareModified',{ref:"testOrderActions",attrs:{"alert-status":_vm.compareData.status,"row-id":_vm.item.id,"table-id":_vm.tableId}}):_vm._e(),(_vm.useScheduleMultipleSelect)?_c('ControlSelects',{attrs:{"value":_vm.checkIsRowSelected(_vm.item.id)},on:{"change":function (v) { return _vm.onSelectedRowsChange(_vm.item.id , v); }}}):_vm._e(),_c('ProductCellOrder',{attrs:{"order":_vm.item.order},scopedSlots:_vm._u([(_vm.showProjectDetailActions)?{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"brightness-icon",attrs:{"medium":"","color":"black"},on:{"click":function($event){return _vm.$emit('setMenuItem', {
            item: _vm.item,
            event: $event,
          })}}},[_vm._v(" mdi-dots-vertical ")])]},proxy:true}:null],null,true)}),_c('ProductCellExpandedView',_vm._g({},_vm.$listeners))]:_c('ProductCell',_vm._g({ref:"tableCell",class:[
      _vm.getActiveRowIds.includes(_vm.item.id) && 'table-container__active-cell',
      _vm.isCellSearchHighlighting({ rowId: _vm.item.id, columnName: _vm.header.value }) && 'v-data-table--schedule__search-highlighting',
      _vm.isSelectedCellSearchHighlighting({ rowId: _vm.item.id, columnName: _vm.header.value }) && 'v-data-table--schedule__search-highlighting__selected',
      _vm.renderedDraggableIndex == _vm.index && !_vm.canBeActiveResized(_vm.index) && 'v-data-table--schedule__dragged-cell',
      ("table-cell--" + _vm.selectedViewCriteria)
    ],attrs:{"id":((_vm.item.id) + "--" + (_vm.header.value)),"bordered-cell":_vm.borderedCell,"data":_vm.data,"disabled-edit-mode":_vm.disabledEditMode || _vm.item.isCompared,"header":_vm.header,"is-expanded-mode":false,"is-opened-in-dialog":false,"item":_vm.item,"transform-images-to-data":_vm.transformImagesToData,"allow-save-on-esc":"","allow-set-multuple-cells":"","allow-show-select-col":"","hide-header":""},scopedSlots:_vm._u([{key:"additionalCellActions",fn:function(ref){
    var isPastingCell = ref.isCellForPasting;
    var isBorderedCell = ref.borderedCell;
return [(!_vm.isUnsuccessfulPayment)?_c('ProductCellCopy',_vm._g({attrs:{"is-selected-cell":isBorderedCell}},_vm.$listeners)):_vm._e(),(isPastingCell && !_vm.isUnsuccessfulPayment)?_c('ProductCellPaste',_vm._g({},_vm.$listeners)):_vm._e()]}},{key:"cellActions",fn:function(ref){
    var doubleClickCell = ref.doubleClickCell;
return [_c('v-icon',{staticClass:"pointer order-2",attrs:{"color":"green","size":"16"},on:{"click":doubleClickCell}},[_vm._v(" mdi-pencil ")])]}},{key:"cellNotification",fn:function(){return [(_vm.isResultCompare && _vm.compareData.rowId === _vm.item.id && _vm.compareData.modified.includes(_vm.header.value))?_c('ProductCellModifiedWarning',{ref:"testActions",attrs:{"row-id":_vm.item.id,"table-id":_vm.tableId}}):_vm._e()]},proxy:true}])},_vm.$listeners))],2)}
var staticRenderFns = []

export { render, staticRenderFns }