import {
  SORT_BOTTOM, SORT_TOP,
} from '@/constants/scheduleViews';
import productHeaders from '@/constants/productHeaders';
export default {
  props: {
    sortBy: {
      type: String,
      default: '',
    },
    sortingData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      SORT_TOP,
      SORT_BOTTOM,
    };
  },
  computed: {
    isCurrentColumnSorting() {
      return this.sortingData.sortBy == this.sortBy || this.sortingData.sortBy == productHeaders.SORTING_COEFFICIENT && this.sortBy == productHeaders.TYPE_DESIGNATION;
    },
    isSortingTop() {
      return this.sortingData.sortOrder == SORT_TOP;
    },
    isSortingBottom() {
      return this.sortingData.sortOrder == SORT_BOTTOM;
    },
  },
  methods: {
    onAction() {
      this.$emit('mouse-action');
    },
  },
};
