<template>
  <app-dialog
    v-model="dialogForControls"
    :value.sync="dialogForControls"
    width="449"
    content-class="v-dialog__form">
    <v-card>
      <v-card-text>
        <div
          style="height: auto;"
          class="mt-5">
          <v-select
            ref="views"
            v-model="selected"
            label="Create a new view from a copy of"
            :items="views"
            item-text="name"
            item-value="id"
            return-object />
        </div>
        <div
          style="height: auto;"
          class="mt-5">
          <div>
            <ActionButtons
              submit-text="Copy"
              cancel-text="Cancel"
              @onCancelClick="onCancelClick"
              @onSubmitClick="onCopy" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>
<script>
export default {
  name: 'ProjectDetailViewCopy',
  components: {
    ActionButtons: () => import('../ProjectDetailControlsViewActions'),
  },
  props: {
    views: {
      type: Array,
      default: () => [],
    },
    selectedView: {
      type: Object,
      default: () => {
      },
    },
    dialogStatus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      viewName: '',
    };
  },
  computed: {
    dialogForControls: {
      get() {
        return this.dialogStatus;
      },
      set(val) {
        this.$emit('update:dialogStatus', val);
      },
    },
    selected: {
      get() {
        return this.selectedView;
      },
      set(val) {
        this.$emit('updateCopiedView', val);
      },
    },
  },
  watch: {
    dialogForControls(val) {
      if (!val) {
        this.viewName = '';
      } else {
        this.$emit('closeAutocomplete');
      }
    },
  },
  methods: {
    onCopy() {
      this.$emit('onCopy');
      this.dialogForControls = false;
    },
    onCancelClick() {
      this.dialogForControls = false;
    },
  },
};
</script>