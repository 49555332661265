<template>
  <span
    v-show="isCurrentColumnSorting"
    @mouseover="onAction"
    @mouseleave="$emit('mouse-action-end')">
    <v-icon
      v-show="(isSortingBottom || sortableOptions[SORT_TOP].sortDefault) && sortableOptions[SORT_TOP].enabled"
      class="pointer"
      small
      @click="onArrowClick(SORT_TOP, sortBy)">
      mdi-arrow-down
    </v-icon>
    <v-icon
      v-show="isSortingTop && sortableOptions[SORT_BOTTOM].enabled"
      class="pointer"
      small
      @click="onArrowClick(SORT_BOTTOM, sortBy)">
      mdi-arrow-up
    </v-icon>
  </span>
</template>
<script>
import sortingMixin from '@/mixins/sortingMixin';
export default {
  name: 'AppSortArrows',
  mixins: [sortingMixin],
  props: {
    sortableOptions: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onArrowClick(sortOrder, sortBy) {
      if (this.sortableOptions[sortOrder].sortDefault) {
        this.$emit('sort-default');
        return;
      }
      this.$emit('sort', {
        sortOrder,
        sortBy,
      });
    },
  },
};
</script>
<style scoped>
</style>
