import { Storage } from 'aws-amplify';
import ProjectDocumentsApi from '@/services/graphql/projectDocuments';
import fileAttempts from '@/mixins/fileAttempts';
import {
  mapState, mapGetters, mapActions,
} from 'vuex';
import { parseStorageKey } from '@/utils';
export default {
  mixins: [fileAttempts],
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('UserProfile', ['identityId']),
    ...mapGetters('ManageFiles', ['getFileByKey']),
    ...mapGetters('FeatureFlags', ['useIncreaseCaching']),
  },
  methods: {
    ...mapActions('ManageFiles', ['parseFile', 'removeFilesFromCache']),
    async onError(file) {
      let path = `${file.id}/${file.filename}`;
      this.addAttempts(path);
      if (!this.haveEnoughAttempts(path)) {
        return;
      }
      this.removeFilesFromCache({
        keys: [path],
      });
      this.images = await this.getAllImagesData(this.images);
    },
    async getUrlForValues() {
      this.spinner(true);
      try {
        const response = await ProjectDocumentsApi.getProjectDocuments({
          tableId: this.scheduleId,
          tableType: 'schedule',
        });
        this.images = await this.getAllImagesData(response.data.response);
      } catch (err) {
        console.log(err);
      } finally {
        this.spinner(false);
      }
    },
    getAllImagesData(valueWithData) {
      return Promise.all(valueWithData.map(async (v) => {
        if (v.key) {
          const { identityId } = parseStorageKey(v.key);
          let path = `${v.id}/${v.filename}`;
          if (this.useIncreaseCaching) {
            await this.parseFile({
              key: path,
              config: {
                level: 'protected',
                identityId,
              },
            });
            v.url = this.getFileByKey(path);
          } else {
            v.url = await Storage.get(
              path, {
                level: 'protected',
                identityId,
              }
            );
          }
        }
        return v;
      }));
    },
    async saveProjectDocuments(files) {
      const identityId = this.identityId;
      let keys = [];
      this.spinner(true);
      await Promise.all(files.map(async (file) => {
        try {
          const { data } = await ProjectDocumentsApi.declareProjectDocument({
            projectId: this.projectId,
            identityId,
            tableId: this.scheduleId,
            tableType: 'schedule',
            filename: file.name,
            size: `${file.size}`,
            type: file.type,
            workspaceId: this.activeWorkspaceId,
          });
          const metadata = {
            identity_id: identityId,
            attachmentId: data.response.id,
            schedule_id: this.scheduleId,
            projectId: this.projectId,
            tableType: 'schedule',
            filename: file.name,
            workspace_id: this.activeWorkspaceId,
            attachmentDocType: 'document',
          };
          const { key } = await Storage.put(`${data.response.id}/${file.name}`, file.file, {
            level: 'protected',
            identityId,
            contentType: file?.type,
            metadata,
          });
          keys.push(key);
          return key;
        } catch (err) {
          this.handleError(err);
        }
      }));
      this.spinner(false);
    },
    async deleteFiles(documentsIds) {
      this.spinner(true);
      try {
        await ProjectDocumentsApi.deleteProjectDocuments({
          tableId: this.scheduleId,
          tableType: 'schedule',
          projectId: this.projectId,
          documentsIds,
          workspaceId: this.activeWorkspaceId,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
