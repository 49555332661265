<template>
  <v-icon
    color="black"
    @click="$emit('showExpandedDialog')">
    mdi-fullscreen
  </v-icon>
</template>
<script>
export default {
  name: 'ProductCellExpandedView',
};
</script>
