// NOTE: Scroll can move to the end because end if elements doesn't drew yet
import {
  mapActions,
  mapGetters,
} from 'vuex';
import {
  KEYBOARD_KEYS, SCROLL_CONFIG,
} from '@/constants/keyboardController';
/** Keyword for marking a cell */
const KEYWORD = 'CELL';
export default {
  data: () => ({
    isInitedKeyboardController: false,
    isEnableScroll: true,
  }),
  watch: {
    /**
     * Handles state of disabled variable: isDisableKeyboardController.
     * */
    isDisableKeyboardController() {
      if (this.customId) {
        this.onDestroyKeyboardController();
        this.onInitKeyboardController();
      }
    },
  },
  computed: {
    /**
     * @mapGetters
     * Getter navigationWithKeyboardInSchedule
     *  Returns flag which allows to init Keyboard Controller.
     * Getter isEditModeEnabled
     *  Returns boolean value which shows status of edit mode.
     * */
    ...mapGetters({
      navigationWithKeyboardInSchedule: 'FeatureFlags/navigationWithKeyboardInSchedule',
      getFirstActiveCell: 'ProjectDetailsTableSchedule/getFirstActiveCell',
      isActiveEditMode: 'ProjectDetailsTableSchedule/isActiveEditMode',
      isDisableKeyboardController: 'ProjectDetailsTableSchedule/isDisableKeyboardController',
    }),
    /**
     * Returns keys of possible events
     * */
    getKeys() {
      return Object.keys(KEYBOARD_KEYS).map(key => KEYBOARD_KEYS[key]);
    },
    /**
     * Returns columns of table
     * @computed
     * */
    getColumns() {
      return this.tableHeaders.map(item => item.value);
    },
    /**
     * Returns rows of table
     * @computed
     * */
    getRows() {
      return this.data.map(item => item.id);
    },
    /**
     * Return first active cell of table.
     * There is can be a situation when user selected several cells.
     * Computed value returns a FIRST selected cell.
     * @computed
     * */
    getActiveCell() {
      if (this.getFirstActiveCell) {
        return this.getFirstActiveCell;
      }
      return {
        rowId: '',
        col: '',
      };
    },
  },
  methods: {
    /**
     * @mapActions
     * Method setSelectedCells
     *  Sets focus on some cells.
     * Method setDisableKeyboardController
     *  Sets state of disabled/enabled variable controller
     * */
    ...mapActions({
      setSelectedCells: 'ProjectDetailsTableSchedule/setSelectedCells',
      setDisableKeyboardController: 'ProjectDetailsTableSchedule/setDisableKeyboardController',
    }),
    /**
     * The method returns a unique idication for cell.
     * @method
     * @param {string} id - Row id (look at getRows method).
     * @param {string} value - Column value (look at getColumns method).
     * NOTE: It looks like that because the method may be necessary use out of mixin.
     * */
    getRefOfCell(id = '', value = '') {
      return `${KEYWORD}:-rowId:${id}-cellId:${value}`;
    },
    /**
     * The method initializes an event listener for handling keyboard events.
     * @method
     * */
    onInitKeyboardController() {
      if (this.isInitedKeyboardController || !this.navigationWithKeyboardInSchedule) {
        return;
      }
      const isColumnsExist = Boolean(this.getColumns.length);
      const isRowsExist = Boolean(this.getRows.length);
      if (!isColumnsExist || !isRowsExist) {
        return;
      }
      try {
        document
          .addEventListener(
            'keydown',
            this.onHandleKey,
            {
              capture: true,
            }
          );
        this.isInitedKeyboardController = true;
      } catch (e) {
        this.onError(e);
      }
    },
    /**
     * The method destroys an event listener for handling keyboard events.
     * @method
     * */
    onDestroyKeyboardController() {
      if (!this.navigationWithKeyboardInSchedule) {
        return;
      }
      try {
        document
          .removeEventListener(
            'keydown',
            this.onHandleKey
          );
        this.isInitedKeyboardController = false;
      } catch (e) {
        this.onError(e);
      }
    },
    /**
     * The method changes disable status of keyboard controller.
     * */
    onClickOutsideTable() {
      if (this.navigationWithKeyboardInSchedule) {
        this.setDisableKeyboardController(true);
      }
    },
    /**
     * The method resets state of disable status of keyboard controller.
     * */
    onResetDisabledTable() {
      if (this.navigationWithKeyboardInSchedule) {
        this.setDisableKeyboardController(false);
      }
    },
    /**
     * The method handles event of keyboard event.
     * @method
     * @param {object} event - KeyboardEvent in which we need only which field.
     * */
    onHandleKey(event = {
    }) {
      const { which = null } = event ?? {
      };
      const isActiveDialogExist = Boolean(
        document.querySelector('.v-dialog__content--active')
      );
      const isIncludesKey = this.getKeys.includes(which);
      if (
        isActiveDialogExist || !isIncludesKey || this.isDisableKeyboardController
      ) {
        return;
      }
      if (this.isPreventKeyInActiveCell(which)) return;
      switch (which) {
      case KEYBOARD_KEYS.UP:
        this.onMoveFocusToRow(true);
        break;
      case KEYBOARD_KEYS.DOWN:
        this.onMoveFocusToRow(false);
        break;
      case KEYBOARD_KEYS.RIGHT:
        this.onMoveFocusToCell({
          next: true,
        });
        break;
      case KEYBOARD_KEYS.TAB:
        event.preventDefault();
        event.stopPropagation();
        this.onMoveFocusToCell({
          next: true, key: which,
        });
        break;
      case KEYBOARD_KEYS.LEFT:
        this.onMoveFocusToCell({
          next: false,
        });
        break;
      case KEYBOARD_KEYS.ESC:
        this.onCloseCell(which);
        break;
      case KEYBOARD_KEYS.ENTER:
        this.onDoubleClickCell();
        break;
      }
    },
    /**
     * The method returns an index of item in array.
     * @method
     * @param {array} array - An array of value. There could be getColumns and getRows.
     * @param {string} item - Value which need to know index.
     * */
    getIndexByValue(array = [], item = '') {
      const index = array.findIndex(element => element === item);
      return index >= 0 ? index : null;
    },
    /**
     * The method moves focus to another cell.
     * @method
     * @param {boolean} next - Value which defines direction of moving cell.
     * @param {number,null} key - Value of key from keyboard that was pressed by user
     * */
    onMoveFocusToCell({ next = false, key = null }) {
      const { rowId, col } = this.getActiveCell ?? {
      };
      const currentColIndex = this.getIndexByValue(this.getColumns, col);
      if (!currentColIndex || (!next && currentColIndex === 1)) {
        return;
      }
      let nextCol = null;
      let prevCol = this.getColumns[currentColIndex];
      if (next) {
        let newIndex = currentColIndex + 1;
        if (newIndex > this.getColumns.length - 1) {
          newIndex = 1;
        }
        nextCol = this.getColumns[newIndex];
      } else {
        let newIndex = currentColIndex - 1;
        if (newIndex < 0) {
          newIndex = this.getColumns.length - 1;
        }
        nextCol = this.getColumns[newIndex];
      }
      if (!nextCol) {
        return;
      }
      const prevRef = this.getRefOfCell(rowId, prevCol);
      const ref = this.getRefOfCell(rowId, nextCol);
      if (!ref || !this.$refs[ref]) {
        return;
      }
      const prevComponent = this.$refs[prevRef]?.$refs?.['tableCell'];
      const prevElement = prevComponent.$el;
      const {
        onUpdateAndClose: update = () => {
        },
      } = prevComponent;
      update(prevElement, key);
      const element = this.$refs[ref].$el?.firstChild;
      if (!element) return;
      element.click();
      if (!this.isEnableScroll) {
        return;
      }
      element.scrollIntoView(SCROLL_CONFIG);
    },
    /**
     * The method moves focus to another row.
     * @method
     * @params {boolean} next - Value which defines direction of moving row.
     * */
    onMoveFocusToRow(next = false) {
      const { rowId, col } = this.getActiveCell ?? {
      };
      const currentRowIndex = this.getIndexByValue(this.getRows, rowId);
      let nextRow = null;
      if (next) {
        let newIndex = currentRowIndex - 1;
        if (newIndex < 0) {
          newIndex = this.getRows.length - 1;
        }
        nextRow = this.getRows[newIndex];
      } else {
        let newIndex = currentRowIndex + 1;
        if (newIndex > this.getRows.length - 1) {
          newIndex = 0;
        }
        nextRow = this.getRows[newIndex];
      }
      const ref = this.getRefOfCell(nextRow, col);
      if (!ref || !this.$refs[ref]) {
        return;
      }
      const element = this.getElementByRef(ref);
      if (!element) {
        return;
      }
      element && element.click();
      if (this.isEnableScroll && element) {
        element.scrollIntoView(SCROLL_CONFIG);
      }
    },
    /**
     The method double-clicks on cell for activating a cell event.
     @method
     */
    onDoubleClickCell() {
      const {
        getActiveCell = {
        },
        isActiveEditMode,
      } = this;
      if (isActiveEditMode) this.onCloseCell();
      else {
        const { rowId, col } = getActiveCell;
        const ref = this.getRefOfCell(rowId, col);
        const targLink = this.getElementByRef(ref);
        if (!targLink) {
          return;
        }
        const clickEvent = document.createEvent('MouseEvents');
        clickEvent.initEvent('dblclick', true, true);
        targLink.dispatchEvent(clickEvent);
      }
    },
    /**
     * The method deletes focus of cell.
     * Also deletes event listener.
     * @method
     * */
    async onDeleteFocus() {
      if (!this.getActiveCell) {
        return;
      }
      this.setSelectedCells([]);
      await this.$nextTick();
      this.onDestroyKeyboardController();
    },
    /**
     * The method moves scroll to end or start depending on direction.
     * @method
     * @params {string} direction - Value which defines direction of scrolling.
     * */
    onMoveScrollTo(direction = 'start') {
      const wrapper = document.querySelector('.v-data-table__wrapper');
      const DIRECTIONS = {
        start: 0,
        end: wrapper.scrollWidth,
      };
      wrapper.scrollTo({
        left: DIRECTIONS[direction],
        behavior: 'smooth',
      });
    },
    /**
     * The method selects first cell.
     * @method
     * */
    onSelectFirstCell() {
      if (!this.navigationWithKeyboardInSchedule) {
        return;
      }
      const rowId = this.getRows[0];
      const col = this.getColumns[1];
      const ref = this.getRefOfCell(rowId, col);
      const element = this.getElementByRef(ref);
      if (!element) {
        return;
      }
      element && element.click();
    },
    /**
     * The method logs error to console.
     * @method
     * @params {string} error - Error message.
     * */
    onError(error = '') {
      if (!error) {
        return;
      }
      console.error(new Error(`TableKeyboardController: ${error}`));
    },
    /**
     * The method returns element which need click.
     * @method
     * @params {string} ref - ref name.
     * */
    getElementByRef(ref = '') {
      if (!ref || !this.$refs[ref]) {
        this.onError('Ref is empty or ref is invalid. Keyboard controller will be destroyed.');
        this.onDestroyKeyboardController();
        return;
      }
      const { $el } = this.$refs[ref];
      const { firstChild } = $el ?? {
      };
      return firstChild;
    },
    /**
     * The method changes edit mode to false.
     * @method
     * @params {number} the key of keyboard
     * */
    onCloseCell(key = null) {
      const { rowId, col } = this.getActiveCell ?? {
      };
      const ref = this.getRefOfCell(rowId, col);
      const component = this.$refs[ref]?.$refs?.['tableCell'];
      const el = component?.$el;
      if (!el) return false;
      const {
        onUpdateAndClose: update = () => {
        },
      } = component;
      update(el, key);
    },
    /**
     * The method return prevent keys
     * @method
     * @params {number} the key of keyboard
     * */
    isPreventKeyInActiveCell(key) {
      return ![KEYBOARD_KEYS.TAB, KEYBOARD_KEYS.ENTER, KEYBOARD_KEYS.ESC].includes(key) && this.isActiveEditMode;
    },
  },
};
