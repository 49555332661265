import {
  mapMutations, mapGetters, mapState,
} from 'vuex';
export default {
  computed: {
    ...mapGetters('FeatureFlags', ['useSkeleton']),
    ...mapState(['isChangingView']),
  },
  methods: {
    ...mapMutations(['setUploadingSpinner', 'setIsChangingView', 'setIsLoadingRowData']),
  },
  updated() {
    this.$nextTick(() => {
      if (this.useSkeleton) {
        if (this.isChangingView) {
          this.setIsChangingView(false);
          setTimeout(() => {
            this.setIsLoadingRowData(false);
          }, 500);
        }
      }
      this.setUploadingSpinner(false);
      if (this.isMasonryPage && this.isListingView) {
        this.$redrawVueMasonry();
      }
    });
  },
};
