<template>
  <div
    class="mx-1 d-flex justify-end align-center"
    style="width: 30px;position: absolute;right:0;top: 12px;z-index: 350;"
    @mouseover="onAction"
    @mouseleave="$emit('mouse-action-end')">
    <v-menu
      offset-y
      nudge-bottom="10"
      content-class="edit-menu"
      :close-on-content-click="true">
      <template #activator="{ on }">
        <v-icon
          class="pointer"
          v-on="on">
          mdi-menu-down
        </v-icon>
      </template>
      <v-card class="nav-card nav-card__actions py-2">
        <div
          v-if="isCurrentColumnSorting"
          class="px-3"
          @click="$emit('sort', {
            sortOrder: SORT_TOP,
            sortBy: defaultSortBy,
          })">
          Remove Sort
        </div>
        <div
          v-else
          class="px-3"
          @click="$emit('sort', {
            sortOrder: SORT_TOP,
            sortBy,
          })">
          Sort by
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import sortingMixin from '@/mixins/sortingMixin';
import productHeaders from '@/constants/productHeaders';
export default {
  name: 'TableHeaderActions',
  mixins: [sortingMixin],
  props: {
    defaultSortBy: {
      type: String,
      default: productHeaders.SORTING_COEFFICIENT,
    },
  },
};
</script>
<style scoped>
  .nav-card__actions {
    min-width: 70px;
  }
</style>
