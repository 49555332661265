<template>
  <div>
    <v-list v-if="usersLength">
      <v-list-item
        v-for="(user, index) in users"
        :key="user.id"
        :class="{'active-user' : currentUser == index}"
        :tabindex="index + 1"
        @click="selectMention(user)">
        <UserListItem
          :item="user"
          :avatar="avatars[user.picture]" />
      </v-list-item>
    </v-list>
    <v-list v-else-if="!usersLength">
      <v-list-item class="mt-0">
        <v-list-item-content class="mb-0">
          <v-list-item-subtitle
            v-if="!showLoader && !isSearching">
            Mention a user...
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-else-if="!showLoader && searchComplete">
            No matches found
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-else
            class="d-flex justify-center">
            <v-progress-circular
              :size="25"
              color="red"
              indeterminate />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import {
  debounce, isEqual, differenceWith, uniqBy,
} from 'lodash';
import {
  mapState, mapActions,
} from 'vuex';
import API from '@/services/graphql';
import { DEBOUNCE_TIME_FOR_SEARCHING } from '@/constants';
import { SOURCERY_ASSISTANT_USER } from '@/constants/comments';

export default {
  name: 'MentionAutocomplete',
  components: {
    UserListItem: () => import('@/components/App/AppShareAutocomplete/AppShareAutocompleteUserListItem'),
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    refOfTextArea: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selected: [],
    users: [],
    currentUser: 0,
    diff: '',
    searchString: '',

    // Give ids to each set of searches so we can cancel older ones.
    latestSearch: 0,
    isSearching: false,
    searchComplete: false,
  }),
  computed: {
    ...mapState(['avatars']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('Projects', ['assistantStatus']),
    usersLength() {
      return this.users.length;
    },
    showLoader() {
      return this.isSearching && !this.users?.length;
    },
  },
  watch: {
    users() {
      this.$emit('userForMentions', this.users);
    },
    search: debounce(function (val, oldValue) {
      this.user = [];
      if (val) {
        const searchId = this.latestSearch += 1;
        this.latestSearch = searchId;
        this.searchComplete = false;

        const id = 'idForString';
        const splitedMessage = val.split(' ').map((item, i) => `${id}${i}${item}`);
        let splitedMessageOld = [];
        if (oldValue !== undefined) {
          splitedMessageOld = oldValue.split(' ').map((item, i) => `${id}${i}${item}`);
        }
        const diff = differenceWith(splitedMessage, splitedMessageOld,
          isEqual);
        const [string = ''] = diff;
        this.diff = string;
        const checkLineBreakLength = diff[0] ? diff[0].split('\n').length : 0;
        const lengthOfIndex = diff[0] ? diff[0].split('idForString').pop().split('@')[0].length - checkLineBreakLength + 1 : 0;
        const searchString = diff[0] ? diff[0].slice(id.length + lengthOfIndex + checkLineBreakLength) : '';

        if (diff.length && searchString.length >= 1) {
          this.searchString = searchString;
          this.searchOfUsers(searchId);

        } else if (!diff.length || diff[0].length
          === (id.length + checkLineBreakLength + lengthOfIndex - 1)) {
          this.$emit('closeMentionMenu');
          this.users = [];
        } else {
          this.users = [];
        }
      } else {
        this.$emit('closeMentionMenu');
        this.users = [];
      }
    }, DEBOUNCE_TIME_FOR_SEARCHING),
  },
  methods: {
    ...mapActions(['getAvatar']),
    selectMention(user) {
      const selectUser = !user ? this.users[this.currentUser] : user;
      this.$emit('selectedMention', selectUser, this.diff);
      this.refOfTextArea.focus();
      this.users = [];
    },
    userDown() {
      if (this.currentUser >= this.usersLength - 1) {
        this.currentUser = this.usersLength - 1;
      } else {
        this.currentUser += 1;
      }
    },
    userUp() {
      if (this.currentUser > 0) {
        this.currentUser -= 1;
      } else {
        this.currentUser = 0;
      }
    },
    async searchOfUsers(searchId) {
      if (searchId !== this.latestSearch) return;

      let first = true;
      let nextToken;

      try {
        this.isSearching = true;

        while (first || nextToken) {
          first = false;

          const { data } = await API.searchProjectMembersV2({
            searchString: this.searchString,
            projectId: this.$route.params.id,
            workspaceId: this.activeWorkspaceId,
            nextToken,
          });

          if (searchId === this.latestSearch) {
            nextToken = this.parseResponseData(data);
            this.addAssistantMention(this.searchString);
          }
        }
      } catch (err) {
        console.log(err);

      } finally {
        if (searchId === this.latestSearch) {
          this.isSearching = false;
          this.searchComplete = true;
        }
      }
    },
    parseResponseData(data) {
      let users = [];
      let nextToken;

      const { response } = data || [];
      if (Array.isArray(response)) {
        users = response;
      } else {
        const { data: usersFromResp, nextToken: tokenFromResp } = response ?? {
        };
        if (usersFromResp) users = usersFromResp;
        nextToken = tokenFromResp;
      }

      if (!nextToken) {
        this.users = [...users];
      } else {
        this.users = [...this.users, ...users];
      }

      this.users = uniqBy(this.users, 'id');
      this.users.forEach(async (item) => {
        if (item.picture && !this.avatars[item.picture]) {
          this.getAvatar({
            key: item.picture,
            getAvatars: true,
            context: this,
          });
        }
      });
      return nextToken;
    },
    addAssistantMention(searchString) {
      if (!searchString) return;
      if (!'assistant'.toLowerCase().startsWith(searchString.toLowerCase())) return;
      if (this.users.some((user) => (user.email == SOURCERY_ASSISTANT_USER.email))) return;
      if (!this.assistantStatus?.invited) return;
      this.users = [
        ...this.users,
        {
          id: this.assistantStatus.id,
          name: SOURCERY_ASSISTANT_USER.name,
          email: SOURCERY_ASSISTANT_USER.email,
        },
      ];
    },
  },
};
</script>
<style scoped lang="scss">
  .active-user {
    background: rgba(0, 0, 0, 0.04);
  }
  .v-list {
    max-height: 265px;
    .v-list-item {
      margin-top: 20px;
      min-height: 40px;
      min-width: 320px;
      &:first-child {
        margin-top: 0;
      }
      &--highlighted {
        &::before {
            opacity: 0 !important;
        }
        &:hover {
          &:before {
            opacity: 0.16 !important;
          }
        }
      }
    }
  }
</style>
