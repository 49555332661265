<template>
  <div>
    <v-skeleton-loader
      v-if="header"
      type="heading" />
    <v-row
      no-gutters
      :class="{'px-10': sidePadding,}"
      class="pt-4">
      <v-col
        v-for="i in items"
        :key="i"
        class="px-2 mb-4"
        :md="md"
        :lg="lg">
        <div
          :class="getImageWrapperClass"
          class="mb-2">
          <v-skeleton-loader
            :width="imageWidth"
            :height="imageHeight"
            type="image" />
        </div>
        <div
          :class="getTextWrapperClass">
          <v-skeleton-loader
            :width="getSkeletonWrapperWidth"
            type="card-heading, list-item-two-line" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ListingSkeleton',
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Number,
      default: 8,
    },
    cols: {
      type: Number,
      default: 3,
    },
    lg: {
      type: Number,
      default: 3,
    },
    md: {
      type: Number,
      default: 3,
    },
    imageWidth: {
      type: Number,
      default: 130,
    },
    imageHeight: {
      type: Number,
      default: 130,
    },
    sidePadding: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: 'center',
      validator(val) {
        return ['left', 'center'].includes(val);
      },
    },
  },
  computed: {
    getSkeletonWrapperWidth() {
      if (this.align == 'left') {
        return this.imageWidth + 30;
      }
      return this.imageWidth;
    },
    getTextWrapperClass() {
      return 'align-items-'.concat(this.align);
    },
    getImageWrapperClass() {
      return 'align-items-image-'.concat(this.align);
    },
  },
};
</script>
<style scoped lang="scss">
.align-items-center {
  display: flex;
  justify-content: center;
}
::v-deep .align-items {
  &-image {
    &-center {
      display: flex;
      justify-content: center;
    }
    &-left {
      display: flex;
      justify-content: flex-start;
    }
  }
  &-center .v-skeleton-loader__card-heading {
    display: flex;
    justify-content: center;
  }
  &-left .v-skeleton-loader__card-heading {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
