import {
  mapGetters, mapMutations, mapState,
} from 'vuex';
export default {
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['activeCellHeader']),
    ...mapGetters({
      selectedCells: 'ProjectDetailsTableSchedule/selectedCells',
      isSelectedCellSearchHighlighting: 'SearchProjectCells/isSelectedCellSearchHighlighting',
    }),
    getActiveCellsNames() {
      return this.selectedCells.map(({ col = '' } = {
      }) => col);
    },
    getActiveRowIds() {
      return this.selectedCells.map(({ rowId = '' } = {
      }) => rowId);
    },
  },
  beforeDestroy() {
    this.setActiveCellHeader('');
  },
  methods: {
    ...mapMutations('ProjectDetailsTableSchedule', ['setActiveCellHeader']),
    onSetHeader(value) {
      this.setActiveCellHeader(value);
    },
    onRemoveHeader() {
      this.setActiveCellHeader('');
    },
  },
};
