<template>
  <v-btn
    color="blue"
    class="mb-3"
    outlined
    @click="$emit('lockUnlockAllFields')">
    {{ textBtn }}
  </v-btn>
</template>
<script>
export default {
  name: 'ProjectDetailsViewsLockEditing',
  props: {
    statusLockedAllFields: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    textBtn() {
      return !this.statusLockedAllFields ? 'lock for editing' : 'unlock for editing';
    },
  },
};
</script>