<template>
  <v-btn
    class="fs-14"
    style="letter-spacing: normal"
    :color="hideResolvedComments ? 'green' : 'black'"
    outlined
    :disabled="disabled"
    @click="toggleResolvedComments(!hideResolvedComments)">
    Hide Resolved
  </v-btn>
</template>
<script>
import {
  mapActions,
  mapState,
} from 'vuex';
export default {
  name: 'ToggleResolvedComments',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('Comments', ['hideResolvedComments']),
  },
  methods: {
    ...mapActions({
      toggleResolvedComments: 'Comments/toggleResolvedComments',
    }),
  },
};
</script>
