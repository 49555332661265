<template>
  <!-- Product cell representing an order -->
  <div :id="`order-${order}`">
    <!-- Slot for additional actions -->
    <slot name="actions" />
  </div>
</template>
<script>
export default {
  name: 'ProductCellOrder',
  props: {
    order: {
      type: Number,
      default: null,
    },
  },
};
</script>
