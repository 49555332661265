const ROW_COMMENTS_RELATED_SUBSCRIPTIONS = [
  {
    criteria: 'subscribeCreateRowCommentPublic',
    action: 'setComment',
  },
  {
    criteria: 'subscribeCreateRowCommentPrivate',
    action: 'setComment',
  },
  {
    criteria: 'subscribeDeleteRowCommentPublic',
    action: 'deleteComment',
  },
  {
    criteria: 'subscribeDeleteRowCommentPrivate',
    action: 'deleteComment',
  },
  {
    criteria: 'subscribePutCommentThumbnailPublic',
    action: 'setComment',
  },
  {
    criteria: 'subscribePutCommentThumbnailPrivate',
    action: 'setComment',
  },
  {
    criteria: 'subscribeRowFollowerChange',
    action: 'manageFollowers',
  },
];
const PROJECT_COMMENTS_RELATED_SUBSCRIPTIONS = [
  {
    criteria: 'subscribeCreateProjectCommentPublic',
    action: 'setComment',
  },
  {
    criteria: 'subscribeCreateProjectCommentPrivate',
    action: 'setComment',
  },
  {
    criteria: 'subscribeDeleteProjectCommentPublic',
    action: 'deleteComment',
  },
  {
    criteria: 'subscribeDeleteProjectCommentPrivate',
    action: 'deleteComment',
  },
];
export {
  ROW_COMMENTS_RELATED_SUBSCRIPTIONS,
  PROJECT_COMMENTS_RELATED_SUBSCRIPTIONS,
};