import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';
import { SORTABLE_ACTIONS_WIDTH } from '@/constants/scheduleViews';
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['role']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('ProjectDetailsTableSchedule', ['hoveredColumn']),
    ...mapGetters('UserRoles', ['canResizeColumns', 'findUserRoleInLibraryForActiveHeader']),
    canWorkWithResizeColumn() {
      let role = this.$route.name == 'collection-library' ? this.findUserRoleInLibraryForActiveHeader : this.role;
      return this.canResizeColumns(role);
    },
    isCanceledHover() {
      return !this.hoveredColumn.clicked;
    },
    isResizable() {
      return this.hoveredColumn[this.headerName]?.isResized;
    },
    isActiveResizable() {
      return this.hoveredColumn[this.headerName]?.active;
    },
    isCanceledResizable() {
      return !this.hoveredColumn[this.headerName]?.clicked;
    },
  },
  methods: {
    ...mapMutations('ProjectDetailsTableSchedule', ['setHoveredColumn']),
    ...mapActions('ScheduleViews', ['updateViewColumnsSize']),
    ...mapActions('Collections', ['updateCollectionColumnSize']),
    onMouseLeave: function(name) {
      this.isCanceledHover && this.setHoveredColumn({
        name,
      });
    },
    onMouseOver: function(name) {
      this.isCanceledHover && this.setHoveredColumn({
        name,
        isResized: true,
      });
    },
    setActiveColumn: function(name) {
      this.setHoveredColumn({
        name,
        active: true,
        isResized: true,
      });
    },
    canBeActiveResized() {
      return this.hoveredColumn.active;
    },
    onResizeColumn({ name }) {
      if (!this.hoveredColumn.clicked) {
        this.setHoveredColumn({
          name,
          isResized: true,
          active: true,
          clicked: true,
        });
      }
    },
    onResizeCallback() {
      const width = arguments?.[3] || NaN;
      if (isNaN(width)) return false;
    },
    onResizeColumnStop: async function ({ width, colId, projectId = null, resourceId, viewId, resourceType, updateBefore = true, isCustomSortable }) {
      const { activeWorkspaceId: workspaceId } = this;
      const size = width - (isCustomSortable ? SORTABLE_ACTIONS_WIDTH : 0);
      const variables = {
        workspaceId,
        resourceType,
        resourceId,
        ...projectId && {
          projectId,
        },
        viewId,
        columnsSize: [{
          id: colId,
          size,
        }],
      };
      if (resourceType == 'schedule') {
        await this.updateViewColumnsSize({
          variables,
          updateBefore,
        });
        return;
      }
      if (resourceType == 'collection') {
        await this.updateCollectionColumnSize({
          variables,
          updateBefore,
        });
      }
    },
  },
};
