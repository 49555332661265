import { isEqual } from 'lodash';
import { mapGetters } from 'vuex';
import { waitTimeout } from '@/utils';
export default {
  data() {
    return {
      isFocusedOnActions: false,
      draggableHeaders: [],
      draggableRef: {
      },
    };
  },
  watch: {
    selectedView: async function() {
      if (!Array.isArray(this.headers) || !this.headers.length) return;
      // Waiting until dom will be rendered
      await waitTimeout(0);
      this.draggableHeaders = this.headers;
    },
  },
  computed: {
    ...mapGetters({
      canDragAndDropColumns: 'UserRoles/canDragAndDropColumnsInSchedule',
      selectedView: 'ScheduleViews/selectedView',
    }),
    renderedDraggableRef() {
      if (!this.draggableRef.ref) {
        return '';
      }
      return this.$refs[this.draggableRef.ref][0].$el;
    },
    renderedDraggableIndex() {
      return this.draggableRef?.index;
    },
  },
  methods: {
    onDragChoose(el) {
      if (this.isFocusedOnActions) {
        return;
      }
      this.draggableRef = {
        ref: el.item.dataset.ref,
        index: Number(el.item.dataset.index),
      };
      if (this.draggableRef.ref) {
        this.renderedDraggableRef.classList.add('v-data-table--schedule__dragged');
      }
      this.$emit('add-table-class', 'v-data-table--schedule__not-frozen');
    },
    onDragUnchoose: function () {
      if (this.draggableRef.ref) {
        this.renderedDraggableRef.classList.remove('v-data-table--schedule__dragged');
      }
      this.draggableRef = {
      };
      this.$emit('remove-table-class', 'v-data-table--schedule__not-frozen');
    },
    onDragEnd: function (item) {
      try {
        if (isEqual(this.draggableHeaders, this.headers)) {
          return;
        }
        const reducerFunc = (acc = [], item) => {
          if (item?.value && item?.value != 'order') {
            acc.push(item?.value);
          }
          return acc;
        };
        const customColumnOrder = this.draggableHeaders.reduce(reducerFunc, []);
        const oldCustomColumnOrder = this.headers.reduce(reducerFunc, []);
        this.$emit('onDragEnd', {
          customColumnOrder,
          oldCustomColumnOrder,
          changes: [item.newIndex, item.oldIndex],
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
