import {
  mapActions, mapGetters,
} from 'vuex';
import {
  SORTABLE_FIELDS_PROJECTS, SORT_TOP, SORT_BOTTOM, SORTABLE_OPTIONS,
} from '@/constants/scheduleViews';
import productHeaders from '@/constants/productHeaders';
import { sortHelper } from '@/utils';
export default {
  data: function() {
    return {
      SORTABLE_FIELDS_PROJECTS,
      SORT_TOP,
      SORT_BOTTOM,
      SORTABLE_OPTIONS,
      productHeaders,
    };
  },
  computed: {
    ...mapGetters('Collections', ['selectedSortingData', 'selectedCollectionView']),
    ...mapGetters('ScheduleViews', ['sortingData', 'isSortingTop', 'isSortingBottom', 'selectedViewTag', 'selectedView']),
  },
  methods: {
    ...mapActions('ScheduleViews', ['setViewsSorting']),
    ...mapActions('Collections', ['setCollectionsViewsSorting']),
    sortHelper,
  },
};
