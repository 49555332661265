<template>
  <!-- Dialog component to display comments -->
  <app-dialog
    v-model="dialog"
    :value.sync="dialog">
    <!-- Activator template slot -->
    <template #activator="{ on, attrs: dialogAttrs }">
      <div
        v-bind="{...dialogAttrs, ...$attrs}"
        v-on="on">
        <AppSummaryCommentsBtn
          :total-unread-comments="totalUnreadCommentsLength"
          btn-name="Comments" />
      </div>
    </template>
    <!-- Expansion panels for comments -->
    <v-card
      v-if="dialog"
      class="main-panel"
      data-test="project_comments_window">
      <!-- Header items for comments section -->
      <v-card-title class="header-items">
        <div class="d-flex align-center">
          <span class="main-text"><b>General Chat</b></span>
          <ToggleResolvedComments class="mt-0 ml-8" />
        </div>
        <div class="comments">
          <span class="main-text mr-2"><b>Comments</b></span>
          <div
            class="action-buttons">
            <!-- Buttons for comment actions -->
            <template v-if="rowsWithCommentsLength">
              <v-btn
                :disabled="disabledReadAllComments"
                large
                class="ex-and-collapse elevation-0"
                @click="readAllComments">
                <span>MARK ALL AS READ</span>
              </v-btn>
              <v-btn
                class="ex-and-collapse elevation-0"
                @click="expandAll">
                <span>Expand all</span>
                <v-icon
                  small>
                  mdi-arrow-expand
                </v-icon>
              </v-btn>
              <v-btn
                class="ex-and-collapse elevation-0"
                @click="collapseAll">
                <span>Collapse all</span>
                <v-icon
                  small>
                  mdi-arrow-collapse
                </v-icon>
              </v-btn>
            </template>
            <!-- Close button for the comments dialog -->
            <span
              class="sourcery__icon-wrapper black"
              @click="dialog = false">
              <v-icon
                color="white"
                size="15">
                mdi-close
              </v-icon>
            </span>
          </div>
        </div>
      </v-card-title>
      <!-- Container for all comments -->
      <v-card-text class="all-comments">
        <div class="general-comments">
          <!-- CommentsCard component for general comments -->
          <CommentsCard
            ref="projectComments"
            :show-comments-modal="generalCommentsModal"
            hide-resolved-toggle
            type="project"
            :mode="null" />
        </div>
        <!-- Default comments section -->
        <v-expansion-panels
          v-model="commentsPanel"
          class="product-comments"
          accordion
          flat
          multiple>
          <div class="default-comments">
            <!-- Skeleton loader for row comments -->
            <template v-if="isShowRowsCommentsSkeleton">
              <v-skeleton-loader
                v-for="i in 4"
                :key="i"
                class="default-comments__skeleton-expansion-item"
                max-height="116"
                type="image" />
            </template>
            <!-- Message when there are no comments -->
            <p
              v-else-if="!isShowRowsCommentsSkeleton && !rowsWithCommentsLength"
              class="mb-0 text-center">
              There are no comments yet.
            </p>
            <!-- Iterating through row comments -->
            <v-expansion-panel
              v-for="(item) in rowsWithComments"
              :key="item.rowId">
              <!-- Header of the row comment panel -->
              <v-expansion-panel-header class="font-weight-bold">
                <div class="d-flex align-center flex-grow-1 gap-1">
                  {{ item.type === '\b' ? '' : item.type }}

                  <!-- CommentBadge component to display comment information -->
                  <p class="ma-0">
                    <CommentBadge
                      class="ml-1 v-badge__expanded"
                      :item="{
                        commentsAmount: slant(item.rowId).commentsAmount,
                        id: item.rowId,
                      }" />
                  </p>
                </div>

                <!-- go to comments -->
                <span class="flex-grow-0 mr-2">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <router-link
                        :to="{ query: {
                          rowId: item.rowId,
                          fromGeneralComments: true,
                        }}"
                        @click.native="goToRowComments(item.rowId)">
                        <img
                          src="@/assets/icons/arrow-share-icon.svg"
                          height="16"
                          width="16"
                          v-on="on">
                      </router-link>
                    </template>
                    <span>Go to row comments</span>
                  </v-tooltip>
                </span>
              </v-expansion-panel-header>

              <!-- Content of the row comment panel -->
              <v-expansion-panel-content>
                <!-- Comments component to display row comments -->
                <Comments
                  :row-id="item.rowId"
                  :data="{
                    comments: item.comments,
                    nextToken: item.nextToken
                  }"
                  :files-src.sync="filesSrc"
                  :edit-mode="false"
                  @getRowComments="getRowComments(item)" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </div>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';
import { Storage } from 'aws-amplify';

import Comments from '@/components/ProjectDetails/ProjectDetailsRowCommentsModal/RowComments';
import CommentsCard
  from '@/components/ProjectDetails/ProjectDetailsRowCommentsModal/RowCommentsCard';
import CommentBadge from '@/components/ProjectDetails/ProjectDetailsCommentBadge';
import ToggleResolvedComments from '@/components/ProjectDetails/ProjectDetailsRowCommentsModal/ToggleResolvedComments';

import GetCommenCreatorAvatar from '@/mixins/getCommentCreatorAvatar';

import CommentsApi from '@/services/graphql/comments';
/**
 * Vue component for managing project summary comments.
 *
 * @component ProjectDetailsSummaryComments
 */
export default {
  name: 'ProjectDetailsSummaryComments',
  components: {
    Comments,
    CommentsCard,
    CommentBadge,
    ToggleResolvedComments,
    AppSummaryCommentsBtn: () => import('@/components/App/AppComments/AppSummaryCommentsBtn'),
  },
  mixins: [GetCommenCreatorAvatar],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    generalCommentsModal: {
      type: Boolean,
      default: false,
    },
    /**
     * A boolean flag indicating whether rows are currently loading.
     *
     * @type {boolean}
     * @default true
     */
    isRowsLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      commentsPanel: [],
      commentForRows: [],
      filesSrc: {
      },
      /**
       * A boolean flag indicating whether rows comments are currently loading.
       *
       * @type {boolean}
       */
      isRowsCommentsLoading: true,
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState(['showSpinner', 'avatars']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters({
      rowIdsForSlants: 'ProjectDetailsTableSchedule/rowIdsForSlants',
      totalUnreadCommentsLength: 'Comments/totalUnreadCommentsLength',
    }),
    ...mapGetters('Comments', ['slant']),
    rowsWithCommentsLength() {
      const { rowsWithComments: arr = [] } = this;
      return arr.length;
    },
    isShowRowsCommentsSkeleton() {
      const { isRowsLoading, isRowsCommentsLoading, rowsWithCommentsLength: length } = this;
      if (length) return false;
      return isRowsLoading || isRowsCommentsLoading;
    },
    disabledReadAllComments() {
      return !this.totalUnreadCommentsLength;
    },
    dialog: {
      get() {
        return this.generalCommentsModal;
      },
      set(val) {
        this.$emit('update:generalCommentsModal', val);
      },
    },
    rowsWithComments() {
      let sortedRowComments = [];
      if (this.commentForRows.length) {
        this.data
          .forEach(item => (this.commentForRows.find(i => {
            if (i.rowId === item.id && this.slant(item.id)?.commentsAmount) {
              sortedRowComments = [...sortedRowComments, i];
            }
          })));
      }
      return sortedRowComments;
    },
  },
  watch: {
    /**
      * Vue lifecycle method that is called when the `dialog` prop changes.
      *
      * @param {boolean} val - The new value of the `dialog` prop.
      */
    async dialog(val) {
      if (val) {
        if (!this.isRowsLoading) {
          /**
           * Check if rows are not currently loading and initiate the process to retrieve them.
           */
          this.handleGetRowsComments();
        }
        this.$router.replace({
          query: {
            ...this.$route.query,
            generalComments: true,
          },
        }).catch(() => {
        });
        return;
      }
      await this.$nextTick();
      this.$router.replace({
        query: {
          ...this.$route.query,
          generalComments: undefined,
          commentId: undefined,
        },
      }).catch(() => {
      });
      this.commentForRows = [];
      this.commentsPanel = [];
    },
    isRowsLoading: {
      handler(val) {
        if (val || !this.dialog) return;
        this.handleGetRowsComments();
      },
    },
  },
  methods: {
    ...mapActions({
      readAllCommentsSlant: 'Comments/readAllCommentsSlant',
      handleError: 'handleError',
    }),
    ...mapMutations({
      spinner: 'spinner',
    }),
    /**
     * Asynchronously retrieves comments for rows and updates loading state.
     */
    async handleGetRowsComments() {
      /**
       * Set the `isRowsCommentsLoading` flag to `true` to indicate that comments are being loaded.
       * @type {boolean}
       */
      this.isRowsCommentsLoading = true;

      const promiseAllArray = [];

      this.data.map(({ id: rowId, type }) => {
        let promiseInput = this.getRowComments({
          rowId,
          type,
        });
        promiseAllArray.push(promiseInput);
      });

      /**
       * Wait for all promises to resolve concurrently.
       * @type {Promise}
       */
      await Promise.all(promiseAllArray);

      /**
       * Set the `isRowsCommentsLoading` flag back to `false` when comments loading is complete.
       * @type {boolean}
       */
      this.isRowsCommentsLoading = false;
    },
    async readAllComments() {
      if (!this.totalUnreadCommentsLength) return;
      try {
        this.spinner(true);
        await CommentsApi.readAllComments({
          projectId: this.$route.params.id,
          workspaceId: this.activeWorkspaceId,
        });
        await this.readAllCommentsSlant();
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    goToRowComments() {
      this.$emit('goToRowComments');
      this.dialog = false;
    },
    async getRowComments({ rowId, nextToken, type, comments }) {
      if (nextToken) {
        this.spinner(true);
      }
      try {
        const response = await CommentsApi.getRowComments({
          projectId: this.$route.params.id,
          rowId,
          tableId: this.scheduleId,
          tableType: 'schedule',
          nextToken,
          scanIndexForward: false,
          versionId: this.$route.query.version,
          workspaceId: this.activeWorkspaceId,
          limit: 1000,
        });
        const { comments: newComments } = response.data.response;
        const sortedComments = this.lodash.orderBy(nextToken
          ? [...comments, ...newComments] : newComments, ['createdDate'], ['asc']);
        sortedComments.forEach(comment => {
          if (comment.thumbnailId && !this.filesSrc.hasOwnProperty(comment.thumbnailId)) {
            this.getThumbnail(comment.thumbnailId);
          }
          this.getAvatarsForComments(comment);
        });
        const updatedRowIndex = this.commentForRows.findIndex(item => item.rowId === rowId);
        if (updatedRowIndex !== -1) {
          this.$set(this.commentForRows, updatedRowIndex, {
            ...this.commentForRows[updatedRowIndex],
            ...response.data.response,
            comments: [...sortedComments],
          });
        } else {
          this.commentForRows = [...this.commentForRows, {
            ...response.data.response,
            rowId,
            type,
            comments: [
              ...sortedComments,
            ],
          }];
        }
      } catch (err) {
        console.log(err);
      } finally {
        if (nextToken) {
          this.spinner(false);
        }
      }
    },
    async getThumbnail(id) {
      try {
        const url = await Storage.get(id, {
          customPrefix: {
            public: '',
          }, level: 'public',
        });
        this.$set(this.filesSrc, id, url);
      } catch (err) {
        console.log('err with set image', err);
      }
    },
    // Create an array the length of our items
    // with all values as true
    expandAll() {
      this.commentsPanel = this.data.map((item, index) => index);
    },
    // Reset the panel
    collapseAll() {
      this.commentsPanel = [];
    },
  },
};
</script>
<style scoped lang="scss">
.main-panel {
  .header-items {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .main-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
    .comments {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .action-buttons {
        display: flex;
        gap: 7px;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        .ex-and-collapse {
          border: 1px solid #B7C2C5;
          border-radius: 5px;
          padding: 0px !important;
          height: 28px !important;
          .v-icon {
            background: #B7C2C5;
            border-radius: 4px;
            padding: 6px;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 126.19%;
            padding: 2px 8px;
          }
        }
      }
    }
  }

  .all-comments {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;

    .general-comments, .product-comments {
      padding: 26px 24px;
    }

    .product-comments {
      border-radius: 0;

      .default-comments {
        flex-grow: 1;
        height: fit-content;
        overflow: auto;

        &__skeleton-expansion-item {
          border: 1px solid var(--v-lightGrey-base);
        }

        .v-expansion-panel {
          border: 1px solid var(--v-lightGrey-base);
        }
      }
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      grid-template-columns: 1fr 1fr;

      .product-comments {
        border-left: 1px solid var(--v-darkGrey-base);
      }

      .default-comments {
        min-height: 546px;
      }
    }
  }
}
</style>
