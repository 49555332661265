<template>
  <div class="h-inherit overflow-y-auto">
    <div class="pb-16 mt-0 sourcery__container">
      <div class="sourcery__grid">
        <BlockElement
          v-for="item in dataToRendering"
          :key="item.id"
          :is-shown="isShown"
          :item="item"
          :data-listing-to-show="dataListingToShow"
          @openUpdateWindow="showCommentsModalTrigger({ rowId: $event.id })" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapGetters, mapActions,
} from 'vuex';
export default {
  name: 'ProjectDetailsGalleryBlock',
  components: {
    BlockElement: () => import('@/components/App/AppListingElements/AppListingBlockElement/BlockElement'),
  },
  props: {
    allowIntersect: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataListingToShow: [
        'Type / Designation',
        'Manufacturer',
        'Model (Manufacturer Series Name)',
      ],
    };
  },
  computed: {
    ...mapGetters({
      isCellSearchHighlighting: 'SearchProjectCells/isCellSearchHighlighting',
      isSelectedCellSearchHighlighting: 'SearchProjectCells/isSelectedCellSearchHighlighting',
    }),
    dataToRendering() {
      const { data, isSelectedCellSearchHighlighting, isCellSearchHighlighting } = this;
      const mappingArr = data.map(item => ({
        ...item,
        isSelectedCellSearchHighlighting: isSelectedCellSearchHighlighting({
          rowId: item.id,
        }),
        isCellSearchHighlighting: isCellSearchHighlighting({
          rowId: item.id,
        }),
      }));
      return mappingArr;
    },
  },
  methods: {
    ...mapActions('ProjectDetailsTableSchedule', ['showCommentsModalTrigger']),
  },
};
</script>
<style scoped lang="scss">
.sourcery__container .sourcery__grid {
  ::v-deep .block-element {
    width: 100%;
    margin: 0;

    .item-image {
      width: 100%;
      aspect-ratio: 305 / 201;
      border-radius: 15px;
      overflow: hidden;

      img {
        height: 100% !important;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>
