<template>
  <SummaryBudgetView
    :columns-to-summary="columnsToSummary"
    :over-condition-obj="overConditionObj"
    :chart-data="getActiveTagTypeSumValues" />
</template>
<script>
import {
  mapState, mapGetters,
} from 'vuex';
import SummaryBudgetView from '@/components/ProjectDetails/ProjectDetailsSummary/ProjectDetailsSummaryContainer';
export default {
  name: 'ProjectDetailsSummaryBudgetView',
  components: {
    SummaryBudgetView,
  },
  data: () => {
    return {
    };
  },
  computed: {
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState('ProjectAggregation', ['aggregationData']),
    ...mapGetters({
      areaUnitTitle: 'ProjectDetails/areaUnitTitle',
      getActiveTagTypeSumValues: 'ProjectAggregation/getActiveTagsTypeSumValues',
      getTotalCost: 'ProjectAggregation/getActiveTagsTotalCost',
    }),
    overConditionObj() {
      return {
        title: `${this.budgetConditionText} Budget`,
        value: this.budgetConditionValue,
        criteria: this.budgetConditionText,
      };
    },
    columnsToSummary() {
      return [
        {
          colTitle: 'Target Budget',
          value: this.targetBudget,
          colValues: [
            {
              title: 'Total Amount', value: this.targetBudget,
            },
            {
              title: `Area (${this.areaUnitTitle})`, value: this.detailProjectData?.area || '0',
            },
            {
              title: `Cost Per ${this.areaUnitTitle}`, value: this.targetCostPerSqFt,
            },
          ],
        },
        {
          colTitle: 'Current Budget Cost',
          value: this.currentCost,
          colValues: [
            {
              title: 'Total Amount', value: this.currentCost,
            },
            {
              title: `Area (${this.areaUnitTitle})`, value: this.detailProjectData?.area || '0',
            },
            {
              title: `Cost Per ${this.areaUnitTitle}`, value: this.currentCostPerSqFt,
            },
          ],
        },
      ];
    },
    budgetConditionText() {
      if (!(this.aggregationData && this.detailProjectData)) return '';
      return this.getTotalCost < this.detailProjectData.baseBudget
        ? 'Under'
        : 'Over';
    },
    budgetConditionValue() {
      if (!(this.aggregationData && this.detailProjectData)) return 0;
      const value = this.detailProjectData.baseBudget - this.getTotalCost;
      return this.$formatCurrency(Math.abs(value));
    },
    targetBudget() {
      const value = this.detailProjectData
        ? this.detailProjectData.baseBudget
        : 0;
      return this.$formatCurrency(value);
    },
    currentCost() {
      return this.$formatCurrency(this.getTotalCost);
    },
    targetCostPerSqFt() {
      let value = 0;
      if (this.detailProjectData
        && this.detailProjectData.area
        && this.detailProjectData.baseBudget) {
        value = this.detailProjectData.baseBudget / this.detailProjectData.area;
      }
      return this.$formatCurrency(value);
    },
    currentCostPerSqFt() {
      let value = 0;
      if (this.detailProjectData?.area
        && this.getTotalCost) {
        value = this.getTotalCost / this.detailProjectData.area;
      }
      return this.$formatCurrency(value);
    },
  },
};
</script>
<style scoped lang="scss">
</style>
