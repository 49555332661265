<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <img
        class="pointer"
        height="16px"
        src="@/assets/icons/copy-icon.svg"
        width="16px"
        @click.stop="$emit('copyContent', isSelectedCell)"
        v-on="on">
    </template>
    <span>Copy content</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'ProductCellCopy',
  props: {
    isSelectedCell: {
      type: Boolean,
      default: false,
    },
  },
};
</script>