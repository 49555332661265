<template>
  <app-icon-btn
    :access="canModifyTableRows(role)"
    :show-tooltip="!canModifyTableRows(role)"
    icon-name="mdi-plus"
    img-class="inverted-icon"
    top
    @click="addNewRow">
    {{ addText }}
  </app-icon-btn>
</template>
<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
export default {
  name: 'TableAddRow',
  props: {
    // Add above/below
    below: {
      type: Boolean,
      default: true,
    },
    rowId: {
      type: String,
      default: null,
    },
    tableType: {
      type: String,
      default: 'schedule',
    },
  },
  computed: {
    ...mapState(['role']),
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapGetters('UserRoles', ['canModifyTableRows']),
    ...mapGetters('ScheduleRows', ['sortedViewInfo']),
    addText() {
      return `Add row ${this.below ? 'below' : 'above'}`;
    },
  },
  methods: {
    ...mapActions({
      createEmptyRow: 'ScheduleRows/createEmptyRow',
    }),
    async addNewRow() {
      let rowNumber = 0;
      if (this.sortedViewInfo && this.sortedViewInfo.length > 0) {
        rowNumber = this.sortedViewInfo.findIndex(row => row.id === this.rowId);
        if (this.below) rowNumber += 1;
      }
      this.createEmptyRow({
        tableId: this.scheduleId,
        tableType: this.tableType,
        projectId: this.$route.params.id,
        timestamp: Date.now(),
        rowNumber,
      });
    },
  },
};
</script>
