import {
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import { throttle } from 'lodash';

const THROTTLE_TIME_TO_UNDO = 100; // Throttle time in milliseconds

/**
 * @mixin
 * @description A Vue 2 mixin for handling undo actions with keyboard shortcuts (Ctrl + Z).
 */
export default {
  created() {
    window.addEventListener('keydown', this.handleUndoKeyPress);
  },
  computed: {
    ...mapState({
      showSpinner: state => state.showSpinner,
    }),
  },
  methods: {
    ...mapActions('UndoActions', {
      undoHandleAction: 'undoHandleAction',
    }),
    ...mapMutations('UndoActions', {
      clearUndoActions: 'clearUndoActions',
    }),

    /**
     * Handles the keyboard shortcut for undo action (Ctrl + Z).
     *
     * @param {KeyboardEvent} event - The keyboard event.
     */
    undoAction(event) {
      if (this.showSpinner) return;
      const { ctrlKey, metaKey, key } = event ?? {
      };
      // Check if Ctrl (or Command on macOS) key is pressed and 'z' key is pressed
      if ((ctrlKey || metaKey) && key === 'z') {
        // Trigger the undo action in the application
        this.undoHandleAction();
      }
    },

    /**
     * Throttled version of undoAction to prevent rapid multiple calls.
     *
     * @param {KeyboardEvent} e - The keyboard event.
     */
    undoActionThrottled: throttle(function (e) {
      this.undoAction(e);
    }, THROTTLE_TIME_TO_UNDO),
    /**
     * Handles the key press event for undo action (Ctrl + Z).
     *
     * @param {KeyboardEvent} event - The keyboard event.
     */
    handleUndoKeyPress(event) {
      this.undoActionThrottled(event);
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleUndoKeyPress);
    this.clearUndoActions();
  },
};
