<template>
  <v-data-table
    :id="customId"
    :headers="headers"
    :items="items"
    :item-class="setItemClass"
    :mobile-breakpoint="0"
    class="v-data-table--schedule v-data-table__wrapper__image-increased v-data-table--schedule__fixed v-data-table--highlighting"
    item-key="id"
    no-data-text=""
    disable-pagination
    fixed-header
    hide-default-footer
    hide-default-header
    must-sort>
    <template #header>
      <slot
        v-bind="{ headers }"
        name="thead">
        <thead class="v-data-table-header">
          <th
            v-for="(head, index) in headers"
            :key="index">
            {{ head.value }}
          </th>
        </thead>
      </slot>
    </template>
    <template
      v-for="(header, index) in headers"
      #[`item.${header.value}`]="{ item }">
      <slot
        name="cellItem"
        :header="header"
        :index="index"
        :item="item" />
    </template>
    <template #foot>
      <div class="pa-8" />
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'TableLayout',
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    customId: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    setItemClass(item) {
      const { rowClasses = [] } = item ?? {
      };
      if (!rowClasses) return [];
      return rowClasses;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-data-table--schedule__fixed {
  height: 100%;

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
}
</style>